import React, { useEffect, useState, useContext } from "react"

import PropTypes from "prop-types"

import StarLight from "../../assets/images/Icons/Star-light.svg"
import Star from "../../assets/images/Icons/Star.svg"
import LogoLight from "../../assets/images/Logo-light.svg"
import Logo from "../../assets/images/Logo.svg"
import { AuthenticationContext } from "../../context"
import Link from "../../utils/link"

import "./LandingNew.scss"

const QrLanding = ({ pageContext }) => {
  const locations = pageContext.locations
  const [isHover, setIsHover] = useState(false)
  const [presentLoc, setPresentLoc] = useState(pageContext.presentLoc)
  const { setLocation } = useContext(AuthenticationContext)

  useEffect(() => {
    setLocation(presentLoc)
    window.scrollTo(0, document.body.scrollHeight)
  }, [])

  const handleMouseEnter = (e) => {
    const locTemp = locations.find((item) => item.id === Number(e.target.id))
    setPresentLoc(locTemp)
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <div className="landing-new">
      <div className="landing-new__top">
        <div className="landing-new__container landing-new__container--top">
          <div className="landing-new__top__first-row">
            <img src={LogoLight} className="landing-new__top__logo" />

            <div className="landing-new__text landing-new__top__first-row__heading">
              <p>Self service laundromat</p>
            </div>

            <img src={Star} className="landing-new__top__first-row__star" />
            <p className="landing-new__text landing-new__top__first-row__text">
              <img src={StarLight} className="landing-new__text__star" />
              DROP OFF PICK UP
            </p>
            <img src={Star} className="landing-new__top__first-row__star" />
            <p className="landing-new__text landing-new__top__first-row__text">
              <img src={StarLight} className="landing-new__text__star" />
              IN APP PAY AND GO
            </p>
          </div>

          <div className="landing-new__top__second-row">
            <p className="landing-new__text">7 days / 7am — 11pm</p>
            <Link to="https://www.instagram.com/washroom_laundry/">
              <p className="landing-new__text">@washroom_laundry</p>
            </Link>
            <Link to="/pricing">
              <p className="landing-new__text">Price List</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="landing-new__middle landing-new__middle--qr">
        <div className="landing-new__container">
          <img src={Logo} className="landing-new__middle__logo" />

          <div className="landing-new__middle__grid">
            {locations.map((place, index) => (
              <>
                <div key={index} className="location-text-container">
                  <Link to={`/${place.slug}/get-started#login`}>
                    <p
                      className="location-text"
                      id={locations[index].id}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {place.address}
                    </p>
                  </Link>
                </div>
                {isHover && (
                  <div
                    className="landing-new__middle__grid__image"
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    id={locations[index].id}
                  >
                    <Link to={`/${presentLoc.slug}/get-started#login`}>
                      <img
                        src={presentLoc.main_photo_url}
                        alt="washroom building"
                      />
                    </Link>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="landing-new__bottom landing-new__bottom--qr" id="login">
        <div className="landing-new__bottom__mob">
          <Link to="https://www.instagram.com/washroom_laundry/">
            <p className="landing-new__text">@washroom_laundry</p>
          </Link>
          <div className="landing-new__bottom__mob__flex">
            <p className="landing-new__text">7 days / 7am — 11pm</p>
            <img
              src={StarLight}
              className="landing-new__text__star"
              style={{ margin: 0 }}
            />
          </div>
          <Link to="/pricing">
            <p className="landing-new__text">Price List</p>
          </Link>
        </div>

        <div className="landing-new-qr">
          <img src={pageContext.presentLoc.qr_code_photo_url} alt="qrCode" />
        </div>

        <Link to="/login" className="landing-new__bottom__login">
          Login
        </Link>
      </div>
    </div>
  )
}

QrLanding.propTypes = {
  pageContext: PropTypes.any.isRequired,
}

export default QrLanding
